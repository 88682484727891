import * as React from "react"
import { useMemo, useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Trash from "../components/trash"
import NowYouKnow from "../components/nowyouknow"
import Related from "../components/related"
import WhatToDo from "../components/whattodo"
import Component from "../components/component"
import DoItWell from "../components/doitwell"
import DoItBetter from "../components/doitbetter"
import useSceenSize from "../hooks/useScreenSize"
import useElementSize from "../hooks/useElementSize"
import styled from "styled-components"
import { Container } from "react-bootstrap"

import { useIntl } from "gatsby-plugin-intl"

const TrashContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  border: 2px solid #84b250;
  border-radius: 20px;
  width: min(15vw, 25vh);
  height: min(15vw, 25vh);
  margin: min(calc(15vw * 0.04), calc(25vh * 0.04));

  background-color: #ffffff;
  @media (max-width: 991px) {
    margin: 0.8vw;
    width: 29vw;
    height: 29vw;
    border: 1px solid #84b250;
  }
`
const TrashNameContainer = styled.div`
  position: absolute;
  width: 100%;
  top: min(calc(15vw * 0.03), calc(25vh * 0.03));
  margin-top: 5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #4e4e4e;
  word-wrap: break-word;
  padding: 0 min(calc(15vw * 0.03), calc(25vh * 0.03));
  font-size: ${props =>
    props.fontSize
      ? `${props.fontSize}px`
      : `min(calc(15vw * 0.06), calc(25vh * 0.06))`};
  line-height: ${props =>
    props.fontSize
      ? `${props.fontSize}px`
      : `min(calc(15vw * 0.06), calc(25vh * 0.06))`};
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    color: #4e4e4e;
  }
  @media (max-width: 576px) {
    font-size: ${props =>
      props.fontSize
        ? `${props.fontSize}px`
        : `min(calc(20vw * 0.06), calc(30vh * 0.06))`};
    line-height: ${props =>
      props.fontSize
        ? `${props.fontSize}px`
        : `min(calc(20vw * 0.06), calc(30vh * 0.06))`};
  }
`
const TrashImage = styled.img`
  height: 80%;
`
const ContainerStyle = styled(Container)`
  background-color: #f8f8f8;
`
const LandscapeWarningContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 5vh;
  font-weight: bold;
  color: #ffffff;
  background-color: #84b250;
`

function randomTrashfunc(trashList, maintrash) {
  let randTrashList = []
  let randNum
  let condition = true

  while (randTrashList.length < 5) {
    randNum = Math.floor(Math.random() * trashList.length)
    if (trashList[randNum].frontmatter.title !== maintrash) {
      condition = true
      for (let x = 0; x < randTrashList.length; x++) {
        if (
          trashList[randNum].frontmatter.title ===
          randTrashList[x].frontmatter.title
        ) {
          condition = false
        }
      }
      if (condition) {
        randTrashList.push(trashList[randNum])
      }
    }
  }
  return randTrashList
}

const TrashItem = ({ searchResult, index }) => {
  const trashRef = React.useRef()
  const nameRef = React.useRef()
  const { height: trashHeight } = useElementSize(trashRef)
  const { height: nameHeight } = useElementSize(nameRef)
  const [fontSize, setFontSize] = useState(undefined)

  useEffect(() => {
    setFontSize(prev => {
      if (!prev) {
        return trashHeight * 0.08
      }

      if (nameHeight > trashHeight * 0.16) {
        return prev - trashHeight * 0.005
      }

      return prev
    })
  }, [nameHeight, trashHeight])

  return (
    <Link
      ref={trashRef}
      to={searchResult.fields.slug.replace(
        "/" + searchResult.frontmatter.language,
        ""
      )}
      itemProp="url"
      id={`trashLink${index}`}
    >
      <TrashContainer
        key={searchResult.fields.slug.replace(
          "/" + searchResult.frontmatter.language,
          ""
        )}
      >
        <TrashNameContainer ref={nameRef} fontSize={fontSize}>
          <p>{searchResult.frontmatter.title}</p>
        </TrashNameContainer>
        <TrashImage
          src={searchResult.frontmatter.trashimage.publicURL}
          alt={searchResult.fields.slug.replace(
            "/" + searchResult.frontmatter.language,
            ""
          )}
        />
      </TrashContainer>
    </Link>
  )
}

const BlogPostTemplate = ({ data, location }) => {
  console.log(location.href)
  const intl = useIntl()
  const post = data.markdownRemark
  const [headerHeight, setHeaderHeight] = useState(0)
  // const trashList = useMemo(()=> {
  //   return data[intl.locale].nodes
  // }, [data, intl.locale])

  // const randomTrash = useMemo(()=> {
  //   return randomTrashfunc(trashList, post.frontmatter.title)
  // }, [trashList, post])

  const { width, height } = useSceenSize()
  const trashList = data[intl.locale].nodes
  // let randomTrash = null
  const titleUppercase = post.frontmatter.title.toUpperCase()

  // randomTrash = randomTrashfunc(trashList, post.frontmatter.title)
  const randomTrash = useMemo(() => {
    return randomTrashfunc(trashList, post.frontmatter.title)
  }, [titleUppercase])

  const posts = useMemo(() => {
    return data[intl.locale].nodes
  }, [data, intl.locale])

  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState(posts)
  const handleChange = event => {
    setSearchTerm(event.target.value)
  }
  const trashContainerParentRef = React.useRef()
  const trashContainerWidth = ""

  useEffect(() => {
    const result = posts.filter(post =>
      post.frontmatter.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setSearchResults(result)
  }, [posts, searchTerm])

  return (
    <>
      {width >= 992 ? (
        <div>
          <Seo
            title={titleUppercase}
            description={intl.formatMessage({ id: "header_intl.seo" })}
          />
          <Header
            state={searchTerm && "search"}
            term={searchTerm}
            handle={handleChange}
            setHeaderHeight={setHeaderHeight}
          />
          {searchTerm ? (
            <ContainerStyle fluid>
              <div
                className="d-flex justify-content-center py-3"
                ref={trashContainerParentRef}
              >
                <div
                  className="d-flex flex-wrap"
                  style={{
                    width: trashContainerWidth
                      ? `${trashContainerWidth}px`
                      : "100%",
                  }}
                >
                  {searchResults.map((searchResult, index) => (
                    <TrashItem searchResult={searchResult} index={index} />
                  ))}
                </div>
              </div>
            </ContainerStyle>
          ) : (
            <>
              <Trash
                state={{
                  trashnumber: post.frontmatter.trashnumber,
                  title: post.frontmatter.title,
                  description1: post.frontmatter.description1,
                  trashimage: post.frontmatter.trashimage,
                }}
                headerHeight={headerHeight}
              />

              {post.frontmatter.components &&
                post.frontmatter.components.length >= 1 &&
                (post.frontmatter.videolink ? (
                  <Component
                    state={{
                      components: post.frontmatter.components,
                      mobilesvg: post.frontmatter.mobilecomponentsvg,
                      videolink: post.frontmatter.videolink,
                    }}
                  />
                ) : (
                  <Component
                    state={{
                      components: post.frontmatter.components,
                      mobilesvg: post.frontmatter.mobilecomponentsvg,
                    }}
                  />
                ))}
              {post.frontmatter.whattodos &&
                post.frontmatter.whattodos.length >= 1 && (
                  <WhatToDo
                    state={{
                      whattodos: post.frontmatter.whattodos,
                      mobilesvg: post.frontmatter.mobilewhattodosvg,
                    }}
                  />
                )}
              {post.frontmatter.doitwells &&
                post.frontmatter.doitwells.length >= 1 && (
                  <DoItWell state={{ doitwells: post.frontmatter.doitwells }} />
                )}
              {post.frontmatter.doitbetters &&
                post.frontmatter.doitbetters.length >= 1 && (
                  <DoItBetter
                    state={{ doitbetters: post.frontmatter.doitbetters }}
                  />
                )}

              <NowYouKnow
                state={{
                  title: post.frontmatter.title,
                  description2: post.frontmatter.description2,
                  trashimage: post.frontmatter.trashimage,
                  location: location.href,
                }}
              />

              {randomTrash && (
                <Related state={{ randomTrashes: randomTrash }} />
              )}

              <Footer />
            </>
          )}
        </div>
      ) : (
        <div>
          <Seo
            title={titleUppercase}
            description={intl.formatMessage({ id: "header_intl.seo" })}
          />
          <Header
            state={searchTerm && "search"}
            term={searchTerm}
            handle={handleChange}
            setHeaderHeight={setHeaderHeight}
          />
          {searchTerm ? (
            <ContainerStyle fluid>
              <div
                className="d-flex justify-content-center py-3"
                ref={trashContainerParentRef}
              >
                <div
                  className="d-flex flex-wrap"
                  style={{
                    width: trashContainerWidth
                      ? `${trashContainerWidth}px`
                      : "100%",
                  }}
                >
                  {searchResults.map((searchResult, index) => (
                    <TrashItem searchResult={searchResult} index={index} />
                  ))}
                </div>
              </div>
            </ContainerStyle>
          ) : (
            <>
              <Trash
                state={{
                  trashnumber: post.frontmatter.trashnumber,
                  title: post.frontmatter.title,
                  description1: post.frontmatter.description1,
                  trashimage: post.frontmatter.trashimage,
                }}
                headerHeight={headerHeight}
              />
              {post.frontmatter.components &&
                post.frontmatter.components.length >= 1 &&
                (post.frontmatter.videolink ? (
                  <Component
                    state={{
                      components: post.frontmatter.components,
                      mobilesvg: post.frontmatter.mobilecomponentsvg,
                      videolink: post.frontmatter.videolink,
                    }}
                  />
                ) : (
                  <Component
                    state={{
                      components: post.frontmatter.components,
                      mobilesvg: post.frontmatter.mobilecomponentsvg,
                    }}
                  />
                ))}
              {post.frontmatter.whattodos &&
                post.frontmatter.whattodos.length >= 1 && (
                  <WhatToDo
                    state={{
                      whattodos: post.frontmatter.whattodos,
                      mobilesvg: post.frontmatter.mobilewhattodosvg,
                    }}
                  />
                )}
              {post.frontmatter.doitwells &&
                post.frontmatter.doitwells.length >= 1 && (
                  <DoItWell state={{ doitwells: post.frontmatter.doitwells }} />
                )}
              {post.frontmatter.doitbetters &&
                post.frontmatter.doitbetters.length >= 1 && (
                  <DoItBetter
                    state={{ doitbetters: post.frontmatter.doitbetters }}
                  />
                )}
              <NowYouKnow
                state={{
                  title: post.frontmatter.title,
                  description2: post.frontmatter.description2,
                  trashimage: post.frontmatter.trashimage,
                }}
              />
              {randomTrash && (
                <Related state={{ randomTrashes: randomTrash }} />
              )}

              <Footer />
            </>
          )}
        </div>
      )}
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        trashnumber
        title
        description1
        trashimage {
          publicURL
        }
        components {
          componentsvg {
            publicURL
          }
        }
        mobilecomponentsvg {
          publicURL
        }
        whattodos {
          whattodosvg {
            publicURL
          }
        }
        mobilewhattodosvg {
          publicURL
        }
        doitwells {
          doitwellsvg {
            publicURL
          }
          doitwelldesc
        }
        doitbetters {
          doitbettersvg {
            publicURL
          }
          doitbetterdesc
          doitbettertitle
        }
        description2
        videolink
      }
    }
    en: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "english" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          language
          trashimage {
            publicURL
          }
        }
      }
    }
    bm: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "malay" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          language
          trashimage {
            publicURL
          }
        }
      }
    }
    tm: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "tamil" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          language
          trashimage {
            publicURL
          }
        }
      }
    }
    cn: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "mandarin" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          language
          trashimage {
            publicURL
          }
        }
      }
    }
  }
`

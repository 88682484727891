import * as React from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.css"
import { Row, Col, Modal, Button } from "react-bootstrap"
import useScreenSize from "../hooks/useScreenSize"
import { useIntl } from "gatsby-plugin-intl"
import ReactPlayer from "react-player/youtube"

const MainContainer = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  line-height: 65px;
  color: #000000;
  weight: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  padding-top: 40px;
  flex-direction: column;
  &:nth-child(even) {
    background-color: #ffffff;
  }
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
  @media (max-width: 991px) {
    padding-top: 70px;
    padding-bottom: 70px;
    height: unset;
    width: unset;
  }
`
const TitleContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`
const Rectangle = styled.div`
  background-color: #84b250;
  width: 200px;
  height: 5px;
  margin: 20px auto 10px;
  @media (max-width: 768px) {
    margin: 10px auto 50px;
  }
`
const TextMain = styled.p`
  font-size: 56px;
  margin-bottom: 0 !important;
  @media (max-width: 1400px) {
    font-size: 45px;
  }
  @media (max-width: 768px) {
    font-size: 36px;
  }
`
// const ImgSize = styled.img`
// width: ${props => (props.length === 1 ? 45 : 25)}vw;
// max-height: ${props => (props.length === 1 ? 65 : 40)}vh;
// @media (max-width: 1400px) {
// width: ${props => (props.length === 1 ? 40 : 25)}vw;
// }
// @media (max-width: 1024px) {
// width: ${props => (props.length === 1 ? 85 : 40)}vw;
// }
// @media (max-width: 768px) {
// width: 90vw;
// }
// `
const ImgSize = styled.img`
  height: ${props => (props.length === 1 ? 50 : 30)}vh;
  @media (max-width: 1100px) {
    height: ${props => (props.length === 1 ? 30 : 12)}vh;
  }
  @media (max-width: 991px) {
    height: ${props => (props.length === 1 ? 60 : 20)}vh;
    max-width: 80vw;
  }
`
const RowCenter = styled(Row)`
  display: flex;
  justify-content: ${props => (props.length === 3 ? "flex-start" : "center")};
  align-items: center;
  width: 100%;
  max-width: 1320px;
`
const ImageDiv = styled.div`
  display: inline-block;
`
const ImageCol = styled(Col)`
  padding-top: 30px;
  padding-bottom: 30px;
`
const PlayButton = styled.button`
  border-radius: 50px;
  background-color: #84b250;
  border: 3px solid #84b250;
  margin: 10px auto 50px;
  @media (max-width: 768px) {
    margin: 10px auto 0px;
    line-height: 40px;
  }
`
const PlayFont = styled.a`
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  padding: 4px 35px;
  color: #fff;
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 30px;
  }
`
const ModalBodyCenter = styled(Modal.Body)`
  display: flex;
  justify-content: center;
  align-items: center;
`

function CenteredModal1(props) {
  const { width } = useScreenSize()
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBodyCenter>
        {width >= 992 ? (
          <ReactPlayer url={props.videolink} controls="true" />
        ) : (
          <ReactPlayer
            url={props.videolink}
            controls="true"
            width="280px"
            height="210px"
          />
        )}
      </ModalBodyCenter>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

const Component = props => {
  const intl = useIntl()
  const { width } = useScreenSize()
  const components = props.state.components
  const mobileSVG = props.state.mobilesvg
  const videolink = props.state.videolink
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <MainContainer>
      <TitleContainer>
        <TextMain>
          {intl.formatMessage({ id: "component_intl.title" })}
        </TextMain>
        <Rectangle />
      </TitleContainer>
      <RowCenter length={components.length}>
        {!mobileSVG ? (
          components.map((component, index) => (
            <ImageCol lg={6} md={12} xs={12} key={index}>
              <ImageDiv>
                <ImgSize
                  length={components.length}
                  src={component.componentsvg.publicURL}
                />
              </ImageDiv>
            </ImageCol>
          ))
        ) : width >= 992 ? (
          components.map((component, index) => (
            <ImageCol lg={6} md={12} xs={12} key={index}>
              <ImageDiv>
                <ImgSize
                  length={components.length}
                  src={component.componentsvg.publicURL}
                />
              </ImageDiv>
            </ImageCol>
          ))
        ) : (
          <ImageCol lg={12} md={12} xs={12}>
            <ImageDiv>
              <ImgSize length={1} src={mobileSVG.publicURL} />
            </ImageDiv>
          </ImageCol>
        )}
      </RowCenter>
      {videolink && (
        <PlayButton>
          <PlayFont onClick={() => setModalShow(true)}>
            WATCH THE DIFFERENCE
          </PlayFont>
        </PlayButton>
      )}
      <CenteredModal1
        show={modalShow}
        onHide={() => setModalShow(false)}
        videolink={videolink}
      />
    </MainContainer>
  )
}

export default Component

import * as React from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.css"
import { Row, Col } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-intl"

const MainContainer = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  line-height: 65px;
  color: #000000;
  weight: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  padding: 40px 0px;
  flex-direction: column;
  &:nth-child(even) {
    background-color: #ffffff;
  }
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
  @media (max-width: 991px) {
    padding-top: 50px;
    margin-top: unset;
    height: unset;
  }
`
const TitleContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: min(3vh, 3vw);
`
const Rectangle = styled.div`
  background-color: #84b250;
  width: 200px;
  height: 5px;
  margin: 10px auto 10px;
  @media (max-width: 768px) {
    margin: 10px auto 50px;
  }
`
const TextMain = styled.p`
  font-size: 56px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0 !important;
  @media (max-width: 1400px) {
    font-size: 45px;
  }
  @media (max-width: 768px) {
    font-size: 36px;
  }
`
const TextSub = styled.p`
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0 !important;
  @media (max-width: 1400px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
`
const RowCenter = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1320px;
`
const ImageDiv = styled.div`
  display: inline-block;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
const ImgSize = styled.img`
  width: 13vw;
  @media (max-width: 1366px) {
    width: 15vw;
  }
  @media (max-width: 991px) {
    width: 40vw;
  }
`
const StepFont = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: min(4vw, 4vh);
  line-height: min(3vw, 3vh);
  text-align: center;
  color: #84b250;
  margin-bottom: 15px;
  margin-top: 10px;
  @media (max-width: 1024px) {
    font-size: min(2.5vw, 2.5vh);
    line-height: min(2.5vw, 2.5vh);
    margin-bottom: 15px;
  }
  @media (max-width: 991px) {
    font-size: min(3vw, 4.4vh);
    line-height: min(3.2vw, 5vh);
  }
  @media (max-width: 600px) {
    font-size: min(4.2vw, 6.2vh);
    line-height: min(4.4vw, 6.4vh);
  }
`
const DescFont = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: min(2.5vw, 2.5vh);
  line-height: min(2vw, 2.8vh);
  text-align: center;
  text-transform: none;
  @media (max-width: 1024px) {
    font-size: min(1.5vw, 2vh);
    line-height: min(1.8vw, 2.4vh);
  }
  @media (max-width: 991px) {
    font-size: min(2vw, 4vh);
    line-height: min(2.4vw, 4.8vh);
  }
  @media (max-width: 600px) {
    font-size: min(4vw, 6vh);
    line-height: min(4.2vw, 6.2vh);
  }
`
const ColStyle = styled(Col)`
  @media (max-width: 991px) {
    margin-bottom: 5vh;
  }
`

const DoItWell = props => {
  const intl = useIntl()
  const doitwells = props.state.doitwells
  let stepcount = 0

  return (
    <MainContainer>
      <TitleContainer>
        <TextMain>{intl.formatMessage({ id: "doitwell_intl.title" })}</TextMain>
        <TextSub>
          {intl.formatMessage({ id: "doitwell_intl.step_to_dispose" })}
        </TextSub>
        <Rectangle />
      </TitleContainer>
      <RowCenter>
        {doitwells.map((doitwell, index) => (
          <ColStyle lg={3} md={12} xs={12} key={index}>
            <ImageDiv>
              <ImgSize src={doitwell.doitwellsvg.publicURL} />
              <StepFont>
                {intl.formatMessage({ id: "doitwell_intl.step" }) +
                  " #" +
                  (stepcount += 1)}
              </StepFont>
              <DescFont>{doitwell.doitwelldesc}</DescFont>
            </ImageDiv>
          </ColStyle>
        ))}
      </RowCenter>
    </MainContainer>
  )
}

export default DoItWell

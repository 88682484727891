import * as React from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.css"
import { Container, Row, Col, Modal, Button } from "react-bootstrap"
import useSceenSize from "../hooks/useScreenSize"
import { useIntl } from "gatsby-plugin-intl"
import {
  FacebookShareButton,
  FacebookIcon as FacebookShareIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

const MainContainer = styled(Container)`
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
  width: 100vw;

  @media (max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: unset;
    height: unset;
  }
`
const DivStyle = styled.div`
  &:nth-child(even) {
    background-color: #ffffff;
  }
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
`
const TrashDescriptionContainer = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    margin: auto;
    text-align: center;
  }
`
const Rectangle = styled.div`
  background-color: #84b250;
  width: 200px;
  height: 5px;
  margin-bottom: 25px;
  @media (max-width: 991px) {
    margin: 0px auto 50px;
  }
`
const ActionNowButton = styled.button`
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 17px;
  background-color: #84b250;
  color: #ffffff;
  border-radius: 50px;
  width: 230px;
  margin-top: 25px;
  margin-right: 15px;
  border: 3px solid #84b250;
  @media (max-width: 1400px) {
    margin-top: 15px;
    font-size: 17px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    width: 220px;
    padding: 5px;
    width: 210px;
    margin: 10px;
  }
`
// const LearnMoreButton = styled.button`
//   background-color: #ffffff;
//   color: #ffffff;
//   border-radius: 50px;
//   width: 230px;
//   margin-top: 50px;
//   border: 3px solid #84b250;
//   @media (max-width: 1920px) {
//     margin-left: unset;
//     margin-top: 15px;
//   }
//   @media (max-width: 1400px) {
//     margin-top: 15px;
//   }
//   @media (max-width: 768px) {
//     width: 200px;
//     padding-left: 5px;
//     padding-right: 5px;
//     margin: 10px;
//     width: 210px;
//   }
// `
const LearnMoreButton = styled.button`
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 17px;
  line-height: 70px;
  background-color: #ffffff;
  color: #ffffff;
  border-radius: 50px;
  width: 230px;
  margin-top: 25px;
  margin-right: 15px;
  border: 3px solid #84b250;
  @media (max-width: 1400px) {
    margin-top: 15px;
    font-size: 17px;
    line-height: 40px;
  }
  @media (max-width: 768px) {
    font-size: 16px;

    width: 200px;
    padding-left: 5px;
    padding-right: 5px;
    width: 210px;
    margin: 10px;
  }
  @media (max-width: 576px) {
    line-height: 38px;
  }
`
const LearnMore = styled.a`
  text-decoration: none;
  color: #ffffff;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 17px;
  line-height: 70px;
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 1400px) {
    font-size: 17px;
    line-height: 40px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 576px) {
    line-height: 38px;
  }
`
const ImgSize = styled.img`
  height: min(35vw, 60vh);
  @media (max-width: 768px) {
    height: 60vw;
  }
  @media (max-width: 576px) {
    height: 50vw;
    margin-bottom: 30px;
  }
`
const ImageCol = styled(Col)`
  display: flex;
  justify-content: end;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    padding-top: 25px;
  }
`
const TextType1 = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  margin-bottom: 0px;
  @media (max-width: 1400px) {
    font-size: 28px;
    line-height: 35px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 30px;
  }
  @media (max-width: 576px) {
    font-size: 22px;
    line-height: 26px;
  }
`
const TextType2 = styled.p`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 60px;
  color: #000000;
  @media (max-width: 1400px) {
    font-size: 48px;
    line-height: 45px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 35px;
  }
  @media (max-width: 576px) {
    font-size: 26px;
    line-height: 28px;
  }
`
const TextType3 = styled.p`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 42px;
  color: #84b250;
  @media (max-width: 1400px) {
    line-height: 35px;
    font-size: 28px;
  }
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 32px;
  }
`
const TrashContent = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  width: 90%;
  @media (max-width: 1400px) {
    width: 100%;
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    padding-bottom: 25px;
  }
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
  }
`
const DescriptionCol = styled(Col)`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`
const RowStyle = styled(Row)`
  width: 100%;
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
`
const ShareDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 25px;
  @media (max-width: 991px) {
    display: block;
    text-align: center;
  }
`
const NotificationText = styled.p`
  margin: auto 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 17px;
  &:hover {
    color: #000000;
  }
  @media (max-width: 1400px) {
    font-size: 17px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
const NotificationText2 = styled.p`
  margin: auto 0px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-size: 17px;
  padding-bottom: 10px;
  &:hover {
    color: #000000;
  }
  @media (max-width: 1400px) {
    font-size: 17px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
const ShareIcons = styled.div`
  display: flex;
  justify-content: center;
`
const ShareIcons2 = styled.div`
  display: flex;
  justify-content: left;
`
const ButtonContainer = styled.div`
  display: grid;
  justify-content: left;
`

function CenteredModal(props) {
  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body>
        <h4 style={{ textAlign: "center" }}>Coming Soon</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

const NowYouKnow = props => {
  const intl = useIntl()
  const [modalShow, setModalShow] = React.useState(false)
  const { width } = useSceenSize()
  const trashURL = props.state.location
  return (
    <DivStyle>
      <MainContainer>
        {width >= 992 ? (
          <RowStyle>
            <DescriptionCol lg={7} md={12} xs={12}>
              <TrashDescriptionContainer>
                <TextType1>
                  {intl.formatMessage({ id: "nowyouknow_intl.title" })}
                </TextType1>
                <TextType2>
                  {intl.formatMessage({
                    id: "nowyouknow_intl.what_to_do_with",
                  })}
                </TextType2>
                <TextType3>{props.state.title}</TextType3>
                <Rectangle />
                <TrashContent>{props.state.description2}</TrashContent>
                <ButtonContainer>
                  <ButtonDiv>
                    <ActionNowButton>
                      <LearnMore
                        href="https://www.zerowastemalaysia.org/bersamakitazerowaste/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {intl.formatMessage({
                          id: "nowyouknow_intl.learn_more",
                        })}
                      </LearnMore>
                    </ActionNowButton>
                  </ButtonDiv>
                  <ShareDiv>
                    <div>
                      <NotificationText2>
                        {intl.formatMessage({ id: "share_intl.share" })}
                      </NotificationText2>
                    </div>
                    <ShareIcons2>
                      <FacebookShareButton
                        url={trashURL}
                        quote="Zero Waste Malaysia"
                      >
                        <FacebookShareIcon size={32} round />
                      </FacebookShareButton>
                      <WhatsappShareButton
                        url={trashURL}
                        title="Zero Waste Malaysia"
                        separator=":: "
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                      <LinkedinShareButton
                        url={trashURL}
                        quote="Zero Waste Malaysia"
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                      <TelegramShareButton
                        url={trashURL}
                        quote="Zero Waste Malaysia"
                      >
                        <TelegramIcon size={32} round />
                      </TelegramShareButton>
                      <TwitterShareButton
                        url={trashURL}
                        quote="Zero Waste Malaysia"
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </ShareIcons2>
                  </ShareDiv>
                </ButtonContainer>
              </TrashDescriptionContainer>
            </DescriptionCol>
            <ImageCol lg={5} md={12} xs={12}>
              <ImgSize src={props.state.trashimage.publicURL} />
            </ImageCol>
          </RowStyle>
        ) : (
          <RowStyle>
            <DescriptionCol lg={7} md={12} xs={12}>
              <TrashDescriptionContainer>
                <TextType1>
                  {intl.formatMessage({ id: "nowyouknow_intl.title" })}
                </TextType1>
                <TextType2>
                  {intl.formatMessage({
                    id: "nowyouknow_intl.what_to_do_with",
                  })}
                </TextType2>
                <Rectangle />
                <ImgSize src={props.state.trashimage.publicURL} />
                <TrashContent>{props.state.description2}</TrashContent>
                <ActionNowButton>
                  <LearnMore
                    href="https://www.zerowastemalaysia.org/bersamakitazerowaste/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage({ id: "nowyouknow_intl.action_now" })}
                  </LearnMore>
                </ActionNowButton>

                <ShareDiv>
                  <div>
                    <NotificationText>
                      {intl.formatMessage({ id: "share_intl.share" })}
                    </NotificationText>
                  </div>
                  <ShareIcons>
                    <FacebookShareButton
                      url={trashURL}
                      quote="Zero Waste Malaysia"
                    >
                      <FacebookShareIcon size={32} round />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={trashURL}
                      title="Zero Waste Malaysia"
                      separator=":: "
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <LinkedinShareButton
                      url={trashURL}
                      quote="Zero Waste Malaysia"
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                    <TelegramShareButton
                      url={trashURL}
                      quote="Zero Waste Malaysia"
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>
                    <TwitterShareButton
                      url={trashURL}
                      quote="Zero Waste Malaysia"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </ShareIcons>
                </ShareDiv>
              </TrashDescriptionContainer>
            </DescriptionCol>
          </RowStyle>
        )}
        <CenteredModal show={modalShow} onHide={() => setModalShow(false)} />
      </MainContainer>
    </DivStyle>
  )
}

export default NowYouKnow

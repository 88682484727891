import * as React from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.css"
import { Row, Col } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-intl"

const MainContainer = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  line-height: 65px;
  color: #000000;
  weight: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  &:nth-child(even) {
    background-color: #ffffff;
  }
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
  @media (max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: unset;
    height: unset;
  }
`
const TitleContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: min(3vh, 3vw);
`
const Rectangle = styled.div`
  background-color: #84b250;
  width: 200px;
  height: 5px;
  margin: 10px auto 25px;
`
const TextMain = styled.p`
  font-size: 56px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0 !important;
  @media (max-width: 1400px) {
    font-size: 45px;
  }
  @media (max-width: 768px) {
    font-size: 36px;
  }
`
const TextSub = styled.p`
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0 !important;
  @media (max-width: 1400px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
`
const RowCenter = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  flex-direction: row;
  max-width: 1320px;
`
const ImgSize = styled.img`
  width: 10vw;
  @media (max-width: 768px) {
    width: 35vw;
  }
`
const ImgMainText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: min(3vw, 3vh);
  line-height: min(3vw, 3vh);
  text-align: center;
  margin-top: 25px;
  text-transform: uppercase;
  word-wrap: break-word;
  @media (max-width: 1300px) {
    font-size: min(2.5vw, 2.5vh);
    line-height: min(2.5vw, 2.5vh);
  }
  @media (max-width: 1024px) {
    font-size: min(1.7vw, 2.2vh);
    line-height: min(2vw, 2.6vh);
  }
  @media (max-width: 768px) {
    font-size: min(2.2vw, 4.2vh);
    line-height: min(2.6vw, 5vh);
  }
  @media (max-width: 576px) {
    font-size: min(4.2vw, 4.2vh);
    line-height: min(4.4vw, 4.4vh);
  }
`
const ImgSubText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: min(1.5vw, 2vh);
  line-height: min(2vw, 2.1vh);
  text-align: center;
  text-transform: none;
  @media (max-width: 1300px) {
    font-size: min(2.3vw, 2.3vh);
    line-height: min(2.4vw, 2.4vh);
  }
  @media (max-width: 1024px) {
    font-size: min(1.5vw, 2vh);
    line-height: min(1.8vw, 2.4vh);
  }
  @media (max-width: 768px) {
    font-size: min(2vw, 4vh);
    line-height: min(2.4vw, 4.8vh);
  }
  @media (max-width: 576px) {
    font-size: min(4vw, 4vh);
    line-height: min(4.2vw, 4.2vh);
  }
`
const ImageDiv = styled.div`
  border: 1px solid #84b250;
  border-radius: 20px;
  background: #ffffff;
  width: 20vw;
  display: inline-block;
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: min(2.25vh, 2.25vw);
  @media (max-width: 1400px) {
    width: 20vw;
  }
  @media (max-width: 1024px) {
    width: 20vw;
  }
  @media (max-width: 991px) {
    width: 50vw;
  }
  @media (max-width: 576px) {
    margin-top: 2.5vh;
    width: 75vw;
  }
`
const Outerdiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
`
const CircleDiv = styled.div`
  height: min(4.5vh, 4.5vw);
  width: min(4.5vh, 4.5vw);
  background-color: #84b250;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: min(1.4vw, 2vh);
  color: #fff;
  @media (max-width: 768px) {
    height: min(6.5vh, 10vw);
    width: min(6.5vh, 10vw);
    font-size: min(3vw, 5vh);
  }
  @media (max-width: 576px) {
    height: 5vh;
    width: 5vh;
    font-size: min(5vw, 7vh);
  }
`

const CircleContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Rectangle2 = styled.div`
  background-color: #84b250;
  width: 30%;
  height: 3px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
`
const ColStyle = styled(Col)`
  @media (max-width: 991px) {
    margin-bottom: 5vh;
  }
`
const DivCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95vw;
`

const DoItBetterImg = ({ doitbetter, count }) => {
  return (
    <ColStyle lg={3} md={12} xs={12}>
      <Outerdiv>
        <CircleContainer>
          <CircleDiv>{count}</CircleDiv>
        </CircleContainer>
        <ImageDiv>
          <ImgSize src={doitbetter.doitbettersvg.publicURL} />
          <ImgMainText>{doitbetter.doitbettertitle}</ImgMainText>
          <Rectangle2 />
          <ImgSubText>{doitbetter.doitbetterdesc}</ImgSubText>
        </ImageDiv>
      </Outerdiv>
    </ColStyle>
  )
}

const DoItBetter = props => {
  const intl = useIntl()
  const doitbetters = props.state.doitbetters
  let numcount = 0

  return (
    <MainContainer>
      <DivCenter>
        <TitleContainer>
          <TextMain>
            {intl.formatMessage({ id: "doitbetter_intl.title" })}
          </TextMain>
          <TextSub>
            {intl.formatMessage({ id: "doitbetter_intl.alternative" })}
          </TextSub>
          <Rectangle />
        </TitleContainer>
        <RowCenter>
          {doitbetters.map((doitbetter, index) => (
            <DoItBetterImg
              doitbetter={doitbetter}
              count={(numcount += 1)}
              key={index}
            />
          ))}
        </RowCenter>
      </DivCenter>
    </MainContainer>
  )
}

export default DoItBetter

import { MutableRefObject, useCallback, useEffect, useState } from "react"
import useMutationObserver from "@rooks/use-mutation-observer"

const useElementSize = (
  ref: MutableRefObject<HTMLElement | null>,
  config = { mutationObserver: true }
) => {
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)

  const updateDimension = useCallback((element?: HTMLElement) => {
    if (!element) return

    setWidth(element.clientWidth)
    setHeight(element.clientHeight)
  }, [])

  useMutationObserver(
    ref,
    () => {
      if (!config.mutationObserver) return

      updateDimension(ref.current)
    },
    { subtree: true, childList: true }
  )

  useEffect(() => {
    const element = ref.current

    if (!element) {
      return
    }

    updateDimension(element)
    setTimeout(() => updateDimension(element), 500)
    const resizeCallback = () => {
      updateDimension(element)
    }

    element.addEventListener("resize", resizeCallback)

    return () => element.removeEventListener("resize", resizeCallback)
  }, [ref, updateDimension])

  return { height, width }
}

export default useElementSize

import * as React from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.css"
import { Container, Row, Col } from "react-bootstrap"
import useSceenSize from "../hooks/useScreenSize"
import { useIntl } from "gatsby-plugin-intl"

const MainContainer = styled(Container)`
  padding-top: ${props =>
    props.headerHeight ? `${props.headerHeight}px` : `0px`};
  padding-bottom: 40px;
  min-height: 95vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:nth-child(even) {
    background-color: #ffffff;
  }
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
  @media (max-width: 768px) {
    padding-top: calc(
      ${props => (props.headerHeight ? `${props.headerHeight}` : `0px`)} + 40px
    );
  }
`
const TrashNumber = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 70px;
  margin-bottom: 0px;
  color: #84b250;
  @media (max-width: 1400px) {
    font-size: 30px;
    line-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`
const TrashTitle = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 56px;
  line-height: 70px;
  color: #000000;
  text-transform: uppercase;
  @media (max-width: 1400px) {
    font-size: 48px;
    line-height: 52px;
  }
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 40px;
    color: #000000;
    word-wrap: break-word;
  }
  @media (max-width: 375px) {
    font-size: 28px;
    line-height: 30px;
  }
`
const TrashDescriptionContainer = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    margin: auto;
    text-align: center;
  }
`
const Rectangle = styled.div`
  background-color: #84b250;
  width: 200px;
  height: 5px;
  margin-bottom: 25px;
  @media (max-width: 991px) {
    margin: 0px auto 25px;
  }
`
const TrashContent = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  width: 100%;
  @media (max-width: 1400px) {
    width: 100%;
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
  }
`
const LearnMoreButton = styled.button`
  background-color: #84b250;
  border-radius: 50px;
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 40px;
  border: 3px solid #84b250;
  @media (max-width: 768px) {
    width: 220px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 25px;
  }
`
const ImgSize = styled.img`
  height: min(35vw, 60vh);
  @media (max-width: 768px) {
    height: 60vw;
  }
  @media (max-width: 576px) {
    height: 50vw;
  }
`
const ImageCol = styled(Col)`
  display: flex;
  justify-content: end;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    padding-top: 25px;
  }
`
const DescriptionCol = styled(Col)`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`
const NavList = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 50px;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 1400px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (max-width: 1200px) {
    font-size: 22px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 30px;
  }
`
const RowStyle = styled(Row)`
  width: 100%;
`

const Trash = props => {
  const intl = useIntl()
  const trashnumber = props.state.trashnumber.toString().padStart(3, "0")
  const { width, height } = useSceenSize()
  // let pageHeight = window.innerHeight;
  // window.scrollBy(0, pageHeight)
  console.log(props.headerHeight)
  return (
    <MainContainer headerHeight={props.headerHeight}>
      <RowStyle>
        <DescriptionCol lg={6} md={12} xs={12}>
          <TrashDescriptionContainer>
            <TrashNumber>{trashnumber}</TrashNumber>
            <TrashTitle>{props.state.title}</TrashTitle>
            <Rectangle />
            <TrashContent>{props.state.description1}</TrashContent>
            <LearnMoreButton
              onClick={() => {
                width >= 992
                  ? window.scrollBy({ top: height, behavior: "smooth" })
                  : window.scrollBy({ top: height - 100, behavior: "smooth" })
              }}
            >
              <NavList>
                {intl.formatMessage({ id: "trash_intl.learn_more" }) + " >"}
              </NavList>
            </LearnMoreButton>
          </TrashDescriptionContainer>
        </DescriptionCol>
        <ImageCol lg={6} md={12} xs={12}>
          <ImgSize src={props.state.trashimage.publicURL} />
        </ImageCol>
      </RowStyle>
    </MainContainer>
  )
}

export default Trash

import * as React from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.css"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

const MainContainer = styled(Container)`
  text-align: center;
  padding: 50px 0;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #000000;
  weight: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  &:nth-child(even) {
    background-color: #ffffff;
  }
  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
  @media (max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: unset;
    height: unset;
  }
`
const TitleContainer = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`
const Rectangle = styled.div`
  background-color: #84b250;
  width: 200px;
  height: 5px;
  margin: 10px auto 50px;
`
const TextMain = styled.p`
  font-size: 56px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0 !important;
  @media (max-width: 1366px) {
    font-size: 45px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
  }
`
const RandomTrashImage = styled.img`
  height: 15vw;
  @media (max-width: 1200px) {
    height: 12vw;
  }
  @media (max-width: 768px) {
    height: 50vw;
  }
`
const HorizontalScroll = styled.div`
  overflow: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 991px) {
    justify-content: flex-start;
  }
`
const OtherTrashContainer = styled.div`
  border: 1px solid #84b250;
  border-radius: 20px;
  padding: 15px;
  margin: 15px;
  width: max(20vw, 40vh);
  @media (max-width: 576px) {
    margin: 5px;
  }
`
const TrashName = styled.p`
  font-size: 15px;
  line-height: 18px;
`

const Related = props => {
  const intl = useIntl()
  const randomTrashes = props.state.randomTrashes

  return (
    <MainContainer fluid>
      <TitleContainer>
        <TextMain>{intl.formatMessage({ id: "related_intl.title" })}</TextMain>
        <Rectangle />
      </TitleContainer>
      <HorizontalScroll>
        {randomTrashes.map((randomTrash, index) => (
          <OtherTrashContainer key={index}>
            <Link
              to={randomTrash.fields.slug.replace(
                "/" + randomTrash.frontmatter.language,
                ""
              )}
              itemProp="url"
            >
              <RandomTrashImage
                src={randomTrash.frontmatter.trashimage.publicURL}
                alt={randomTrash.fields.slug.replace(
                  "/" + randomTrash.frontmatter.language,
                  ""
                )}
              />
            </Link>
            <TrashName>{randomTrash.frontmatter.title}</TrashName>
          </OtherTrashContainer>
        ))}
      </HorizontalScroll>
      {/* <RelatedTrashContainer/> */}
    </MainContainer>
  )
}

export default Related
